import fetch from 'isomorphic-fetch';

export function GetFromDb(url) {
return fetch(url, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    },
    redirect: "follow", 
}).then(function(response) {
    return response.json();
}).then(function(jsonData) {
    return jsonData;
})
}