import React, { Component } from 'react';
import { Container, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, Row, Col, Spinner } from 'reactstrap';
import './Admin.css'
import PromotionUploader from '../components/PromotionUploader';
import PromotionTable from '../components/PromotionTable';
import TestimonialUploader from '../components/TestimonialUploader';
import TestimonialTable from '../components/TestimonialTable';
import AlbumUploader from '../components/AlbumUploader';
import AlbumTable from '../components/AlbumTable';
import GalleryUploader from '../components/GalleryUploader.js';
import AlbumEditor from '../components/AlbumEditor.js';
import DateManagerEditor from '../components/DateManagerEditor.js';
import DateManagerTable from '../components/DateManagerTable.js';
import BookingEditor from '../components/BookingTable.js';
export default class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.getBlockedDatesAgain = this.getBlockedDatesAgain.bind(this);
    this.getPromotionsFromDbAgain = this.getPromotionsFromDbAgain.bind(this);
    this.getTestimonialsFromDbAgain = this.getTestimonialsFromDbAgain.bind(this);
    this.getAlbumsFromDbAgain = this.getAlbumsFromDbAgain.bind(this);
    this.getDatesAgain = this.getDatesAgain.bind(this);
    this.state = {
      dropdownOpen: false,
      promotions: [],
      albums: [],
      dates: [],
      disabledDatesForCal: [],
      bookedDates: [],
      dropDownValue: 'Select an area',
      promotionTableContent: [],
      testimonialTableContent: [],
      albumTableContent: [],
      imagesTableContent: [],
      bookedDatesTableContent:[],
      blockedDatesTableContent:[],
      promotionsLoaded: false,
      testimonialsLoaded: false,
      albumsLoaded:false,
      imagesLoaded:false,
      bookedDatesLoaded:false,
      blockedDatesLoaded:false,
    }
  }

  componentWillMount(){
    this.setState({authenticated:this.props.authenticated})
  }
  componentDidMount() {
    this.getPromotionsFromDb();
    this.getTestimonialsFromDb();
    this.getAlbumsFromDb();
    this.getBookedDatesFromDb();
    this.getBlockedDatesFromDb();
  }
  getPromotionsFromDb = async () => {
    const that = this;
    await fetch("finesse/GetPromotions.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (jsonStr) {
        
        that.setState({ promotionTableContent: jsonStr, promotionsLoaded: true }, function () {});
      }).catch(error => { console.log('request failed', error); });;
  }

  getTestimonialsFromDb = async () => {
    const that = this;
    await fetch("finesse/GetTestimonials.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (jsonStr) {
        
        that.setState({ testimonialTableContent: jsonStr, testimonialsLoaded: true }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  getAlbumsFromDb = async () => {
    const that = this;
    await fetch("finesse/GetAlbums.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (jsonStr) {
        that.setState({ albumTableContent: jsonStr, albumsLoaded: true }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  getBookedDatesFromDb = async () => {
    const that = this;
    await fetch("finesse/GetBookedDates30Days.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (jsonStr) {
        that.setState({ bookedDatesTableContent: jsonStr, bookedDatesLoaded: true }, function () { });
      }).catch(error => { console.log('request failed', error); });;
  }

  getBlockedDatesFromDb = async () => {
    const that = this;
    await fetch("finesse/GetBlockedDates.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        return jsonData;
      })
      .then(function (jsonStr) {
        that.setState({ blockedDatesTableContent: jsonStr, blockedDatesLoaded: true }, function () { });
      }).then(collection => {
        var disabledDatesArrary = [];
        var final = [];
        var i;
        var dates = []
        dates = this.state.blockedDatesTableContent
        for (i = 0; i < dates.length; i++) {
          var Dataarray = [];
          Dataarray = dates[i]['date'];
          final = { Dataarray }
          disabledDatesArrary.push(final);
        }
      
        var j;
        var finalDisabledDates = [];
        for (j = 0; j < disabledDatesArrary.length; j++) {
          var array = [];
          array = disabledDatesArrary[j]['Dataarray'];
          var date = new Date(array);
          finalDisabledDates.push(date)
          
        }
        this.setState({ disabledDatesForCal2: finalDisabledDates })

      }).catch(error => { console.log('request failed', error); });
  }

  getBlockedDatesAgain() {
    this.getBlockedDatesFromDb();
  }

  getTestimonialsFromDbAgain(){
    this.getTestimonialsFromDb();
  }
  getPromotionsFromDbAgain(){
    this.getPromotionsFromDb();
  }

  getAlbumsFromDbAgain(){
    this.getAlbumsFromDb();
  }

  getDatesAgain(){
    console.log("hit the admin page!")
    this.getBookedDatesFromDb();
  }


  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  changeValue(e) {
    this.setState({ dropDownValue: e.currentTarget.textContent })
  }

  displayDropDown() {
    
  }
  displayArea(value) {
    switch (value) {
      case "Manage Promotions and Testimonials":
        return (
          <div>
            <h1>Promotions</h1>
            <PromotionUploader action={this.getPromotionsFromDbAgain} />
            <PromotionTable action={this.getPromotionsFromDbAgain} apiInfo={this.state.promotionTableContent} />
            <h1>Testimonials</h1>
            <TestimonialUploader action={this.getTestimonialsFromDbAgain}/>
            <TestimonialTable action={this.getTestimonialsFromDbAgain} apiInfo={this.state.testimonialTableContent}/>
          </div>
        );

      case "Manage Albums and Galleries":
        return (<div>
          <h1>Albums</h1>
          <AlbumUploader  action={this.getAlbumsFromDbAgain} />
          <AlbumTable action={this.getAlbumsFromDbAgain} apiInfo={this.state.albumTableContent}/>
          <h1>Gallery Uploader</h1>
          <GalleryUploader action={this.getAlbumsFromDbAgain} apiInfo={this.state.albumTableContent}/>
          <AlbumEditor api2Info={this.state.albumTableContent} />
        </div>
        );
      case "Block Dates From Calendar":
        return (<div>
          <h1>Date Manager</h1>
          <DateManagerEditor apiInfo = {this.state.blockedDatesTableContent} dates={this.state.disableDates} action={this.getBlockedDatesAgain} disabledDates={this.state.disabledDatesForCal2} />
          <DateManagerTable dates={this.state.blockedDatesTableContent} action={this.getBlockedDatesAgain} />
        </div>
        );
      case "Manage Bookings":
        return (<div>
          <h1>Manage Bookings</h1>
          <BookingEditor action={this.getDatesAgain} apiInfo={this.state.bookedDatesTableContent} bookedDates={this.state.bookedDates} />
        </div>
        );
      default:
        return (<div style={{ height: '70vh' }}>
          <h1>Welcome to The Admin Center</h1>
          <h3>Select an area to manage from the dropdown above</h3>
        </div>)
    }
  }

  displayPage(){
    if(this.props.authenticated){
      if (!this.state.promotionsLoaded && !this.state.albumsLoaded && !this.state.imagesLoaded 
        && !this.state.bookedDatesLoaded && !this.state.blockedDatesLoaded && !this.state.testimonialsLoaded) {
         
        return (
          <Container style={{ height: '70vh' }}>
          <Row>
            <Col>   
              <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
            </Col>
          </Row>
          <Row>
            <Col>
            <h1>Please wait whilst your admin center loads</h1>
            </Col>
          </Row>
          </Container>
        )
      }
      else {
        return (
          <Row>
            <Col>
              <Dropdown style={{ marginBottom: 50 }} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                  {this.state.dropDownValue}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem><div onClick={this.changeValue}>Manage Promotions and Testimonials</div></DropdownItem>
                  <DropdownItem><div onClick={this.changeValue}>Manage Albums and Galleries</div></DropdownItem>
                  <DropdownItem><div onClick={this.changeValue}>Block Dates From Calendar</div></DropdownItem>
                  <DropdownItem><div onClick={this.changeValue}>Manage Bookings</div></DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {
                this.displayArea(this.state.dropDownValue)
              }
            </Col>
          </Row>
        )
      }
    }
    else{
      this.props.history.push({pathname:'/'})
    }
  }
  render() {


    return (
      <Container fluid={true}>

        {this.displayPage()}


      </Container>
    )
  }

}