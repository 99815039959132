import React, { Component } from 'react';
import '../App.css';
import {
  Container,
  Row,
  Col,
  Button, Form, FormGroup, Label, Input, FormFeedback,Spinner
} from 'reactstrap';
import Calendar from 'react-calendar';
import 'react-accessible-accordion/dist/fancy-example.css';
import {GetFromDb} from '../scripts/GetFromDb';
import { db } from '../components/firebase.js';
import { postData } from '../scripts/PostData';


export default class Book extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.book = React.createRef();
    this.state = {
      isOpen: false,
      package: null,
      date: "",
      fullName: null,
      location: null,
      email: null,
      comments: null,
      loading: true,
      dates: [],
      disabledDatesForCal: [],
      bookedDatesForCal: [],
      dates2: [],
      date3:[],
      displayForm: true,
      dateIsValid: true,
      fullNameisValid: true,
      emailIsValid: true,
      phoneNumberIsValid: true,
      gotBookings:false,
      validate: {
        emailState: '',
        fullNameState: '',
        phoneNumberState: '',
        packageState: '',
        dateState: '',
      },
      arrivalMethod:"",
    };
  }

  hideSpinner = () => {
    this.setState({
      loading: false
    });
  };

 async componentWillMount() {
    this.setState({authenticated:this.props.authenticated});
  if( typeof this.props.location != 'undefined'){
  if (typeof this.props.location.state != 'undefined') {
    if (typeof this.props.location.state.packageType != 'undefined') {
      this.setState({ packageType: this.props.location.state.packageType, package: this.props.location.state.packageType })
    }
  }
}
    await this.getBlockedDates()
    await this.getBookedDatesFromDb();
      
    
      var arr1 = []
      var arr2 = []
      var arr3 = []
      arr1 = this.state.disabledDatesForCal
      arr2 = this.state.bookedDatesForCal
      arr3 = [...arr1, ...arr2]
      this.setState({disabledDatesForCal:arr3})
    

  }
  componentDidMount() {
    
    //this.getDates();
    this.faqs = React.createRef();
    this.prices = React.createRef();
  }
  scroll(ref) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: "center" })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onChange = date => this.setState({ date })

  convertDate(date) {
    var dateTest = new Date(date);
    return (
      dateTest.toLocaleString('en-GB').substring(0, dateTest.toLocaleString('en-GB').indexOf(','))
    )
  }

  async getBlockedDates(){
   await GetFromDb('/finesse/GetBlockedDates.php').then(response =>{
      this.setState({dates2:response});
    }).then(b =>{
      console.log();
    })
    .then(e => {
      var disabledDatesArrary = [];
      var final = [];
      var i;
      var dates2 = []
      dates2 = this.state.dates2;
      
      for (i = 0; i < this.state.dates2.length; i++) {
        var Dataarray = [];
        Dataarray = dates2[i]['date'];
        final = { Dataarray }
        disabledDatesArrary.push(final);

      }
      var j;
      var finalDisabledDates = [];
      for (j = 0; j < disabledDatesArrary.length; j++) {
        var array = [];
        array = disabledDatesArrary[j]['Dataarray'];
        var date = new Date(array);
        finalDisabledDates.push(date)
      }
      this.setState({ disabledDatesForCal: finalDisabledDates })
    }).then(f => {
      
    });
  

          
    
  }

  async getBookedDatesFromDb(){
    await GetFromDb('/finesse/GetBookedDates.php').then(response =>{
       this.setState({dates3:response});
     }).then(b =>{
       console.log();
     })
     .then(e => {
       var bookedDatesForCal = [];
       var final = [];
       var i;
       var dates3 = []
       dates3 = this.state.dates3;
       
       for (i = 0; i < this.state.dates3.length; i++) {
         var Dataarray = [];
         Dataarray = dates3[i]['date'];
         final = { Dataarray }
         bookedDatesForCal.push(final);
 
       }
       var j;
       var finalDisabledDates = [];
       for (j = 0; j < bookedDatesForCal.length; j++) {
         var array = [];
         array = bookedDatesForCal[j]['Dataarray'];
         var date = new Date(array);
         finalDisabledDates.push(date)
       }
       this.setState({ bookedDatesForCal: finalDisabledDates })
     }).then(f => {
       this.setState({gotBookings:true});
     }).catch(error =>{
       this.setState({bookedDateForCal:[], gotBookings:true});
     });
     console.log()
     this.setState({gotBookings:true});
           
     
   }


  async getDates() {
    db
      .collection('blockedDates')
      .get()
      .then(collection => {
        var dates = [];
        dates = collection.docs.map(doc => doc.data())
        const ids = collection.docs.map(doc => doc.id)
        this.setState({ ids }, function () { })
        var final = [];
        var finalArr = [];
        var i;
        for (i = 0; i < dates.length; i++) {
          var Dataarray = [];
          Dataarray = dates[i];
          var IdArray = [];
          IdArray = ({ "key": ids[i] });
          final = { ...IdArray, ...Dataarray };
          finalArr.push(final);
        }
        this.setState({ dates: finalArr }, function () { })
      }).then(collection => {
        var disabledDatesArrary = [];
        var final = [];
        var i;
        var dates = []
        dates = this.state.dates

        for (i = 0; i < this.state.dates.length; i++) {
          var Dataarray = [];
          Dataarray = dates[i]['Date']['seconds'];
          final = { Dataarray }
          disabledDatesArrary.push(final);

        }
        var j;
        var finalDisabledDates = [];
        for (j = 0; j < disabledDatesArrary.length; j++) {
          var array = [];
          array = disabledDatesArrary[j]['Dataarray'];
          var date = new Date(array * 1000);
          finalDisabledDates.push(date)
        }
        this.setState({ disabledDatesForCal: finalDisabledDates })

      })
  }

  onFormSubmit() {
    console.log("the value of date is:" + this.state.date)
    if (this.state.phoneNumberIsValid && this.state.fullNameisValid && this.state.emailIsValid
      && this.state.fullName != null && (this.state.phoneNumber != null || this.state.email != null) && this.state.dateIsValid) {
      var data = {
        Package: this.state.package,
        Date: new Date(this.state.date).getTime() + 6000000,
        fullName: this.state.fullName,
        location: this.state.location,
        Email: this.state.email,
        Telephone: this.state.phoneNumber,
        Comments: this.state.comments,
        Status: true,
      }
      console.log("here's the date sent in the email: " + this.state.date);
      postData(JSON.stringify(data), '/finesse/AddBookedDates.php');
      var emailData = {
        name: this.state.fullName,
        date: new Date(this.state.date).getTime() + 6000000,
        location: this.state.location,
        email: this.state.email,
      }
      postData(JSON.stringify(emailData), '/finesse/newBooking.php')
      this.setState({ date: "" });
      
      this.getDates();
      this.showThankYouMessage()
    }

    if(this.props.arrivalMethod !== 'undefined'){
      if(this.props.arrivalMethod === "admin"){
      this.props.action();
      this.props.close();
      }
    }
  }

  validateEmail(e) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { validate } = this.state
    if (emailRex.test(e.target.value)) {
      validate.emailState = 'has-success'
      this.setState({ emailIsValid: true })
    } else {
      validate.emailState = 'has-danger'
      this.setState({ emailIsValid: false })
    }
    this.setState({ validate })
  }

  validatefullName(e) {
    const { validate } = this.state
    const namerex = /^[a-zA-Z\s]*$/;
    if (namerex.test(e.target.value)) {
      validate.fullNameState = 'has-success'
      this.setState({ fullNameisValid: true })
    } else {
      validate.fullNameState = 'has-danger'
      this.setState({ fullNameisValid: false })
    }
    this.setState({ validate })
  }


  validatePhoneNumber(e) {
    const { validate } = this.state
    const phonerex = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
    if (phonerex.test(e.target.value)) {
      validate.phoneNumberState = 'has-success'
      this.setState({ phoneNumberIsValid: true })
    } else {
      validate.phoneNumberState = 'has-danger'
      this.setState({ phoneNumberIsValid: false })
    }
    this.setState({ validate })
  }

  validateDate(e) {
    const { validate } = this.state
    var property = {...this.state.validate}
    
    if(this.state.date !== ""){
      validate.dateState = 'has-success'
      this.setState(property)
    } else {
      validate.dateState = 'has-danger'
      this.setState(property)
    }
  }
  validatePackage(e) {

  }

  validateEverything(){
    const { validate } = this.state
    var property = {...this.state.validate}
    if(!this.state.phoneNumberState){
      validate.phoneNumberState = 'has-danger'
      this.setState({ phoneNumberIsValid: false })
    }
    else{
      validate.phoneNumberState = 'has-success'
      this.setState(property)
    }
    if(this.state.date !== ""){
      validate.dateState = 'has-success'
      this.setState(property)
    } else {
      validate.dateState = 'has-danger'
      this.setState({dateIsValid:false})
    }

    if(!this.state.fullNameState){
      validate.fullNameState = 'has-danger'
      this.setState({ fullNameisValid: false})
    }
    else{
      validate.fullNameState = 'has-success'
      this.setState(property)
    }
    if(!this.state.emailState){
      validate.emailState = 'has-danger'
      this.setState({ emailIsValid: false})
    }
    else{
      validate.emailState = 'has-success'
      this.setState(property)
    }

  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  }

  displayCalendar(){
    if(this.state.gotBookings){
      return(
        <div>
        <Calendar 
        minDate={new Date()}
        tileDisabled={({ date, view }) => this.state.disabledDatesForCal.some(disabledDate => date.getDate() === disabledDate.getDate() && date.getMonth() === disabledDate.getMonth() && date.getFullYear() === disabledDate.getFullYear())
            }
              onChange={this.onChange}
              value={this.state.date}
            />

            </div>
      )
    }
    else{
      return(
        <div>
        <Row>
        <Col>   
        <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
      </Col>
    </Row>
    <Row>
      <Col>
      <h1>Please wait, getting available dates</h1>
      </Col>
    </Row>
    </div>
      )
    }
  }

  displayBookingForm() {
    if (this.state.displayForm) {
      return (
        <Row>
          <Col>
          {this.displayCalendar()}
          </Col>
          <Col>
            <Form>
              <FormGroup>
                <Label for="package">Package</Label>
                <Input
                  type="select"
                  name="package"
                  id="package"
                  value={this.state.package}
                  onChange={this.handleChange}>
                  <option value="">Please select a package</option>
                  <option value="Gold">Gold</option>
                  <option value="Silver">Silver</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Kids">Kids</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="fullName">Date</Label>
                <Input type="text"  valid={this.state.validate.dateState === 'has-success'}
                  invalid={this.state.validate.dateState === 'has-danger'} value={this.convertDate(this.state.date)} name="date" id="date" disabled placeholder="Please select a date from the calendar" />
              <FormFeedback valid />
                <FormFeedback>
                  Uh oh! Please select a date!
              </FormFeedback>     
              </FormGroup>
              <FormGroup>
                <Label for="fullName">Full Name</Label>
                <Input type="text" value={this.state.fullName}
                  valid={this.state.validate.fullNameState === 'has-success'}
                  invalid={this.state.validate.fullNameState === 'has-danger'}
                  onChange={(e) => {
                    this.validatefullName(e)
                    this.handleChange(e)
                  }} name="fullName" id="fullName" placeholder="Full Name" />
                <FormFeedback valid />
                <FormFeedback>
                  Uh oh! Please only include letters in this box!
              </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="location">Location</Label>
                <Input type ="text" value={this.state.location}
                  onChange={(e) => {
                    this.handleChange(e)
                  }} name="location" id="location" placeholder="Location including venue" />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  value={this.state.email}
                  valid={this.state.validate.emailState === 'has-success'}
                  invalid={this.state.validate.emailState === 'has-danger'}
                  onChange={(e) => {
                    this.validateEmail(e)
                    this.handleChange(e)
                  }}
                />
                <FormFeedback valid />
                <FormFeedback>
                  Uh oh! Looks like there is an issue with your email. Please input a valid email.
              </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="phoneNumber">Phone</Label>
                <Input type="tel" name="phoneNumber" id="phoneNumber" valid={this.state.validate.phoneNumberState === 'has-success'}
                  invalid={this.state.validate.phoneNumberState === 'has-danger'}
                  onChange={(e) => {
                    this.validatePhoneNumber(e)
                    this.handleChange(e)
                  }} />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Add your own notes</Label>
                <Input type="textarea" style={{ height: 100 }} onChange={e => this.setState({ comments: e.target.value })} name="text" id="exampleText" placeholder="Please include preferred method of contact and any discount codes" />
              </FormGroup>
              <Button onClick={(e) => { this.validateEverything(e); this.onFormSubmit();  this.validatePackage(); }}>Submit</Button>
            </Form>
          </Col>
        </Row>

      )
    }
    else {
      return (
        <Row>
          <Col>
            <div style={{ height: "65vh" }}>
              Thank you for submitting your booking query. We'll be in touch within 24 hours to complete the process! <br/>
              If you don't receive an email in the next 30 minutes, please check your spam folder
      </div>
          </Col>
        </Row>

      )
    }
  }

  showThankYouMessage() {
    return (
      this.setState({ displayForm: false })
    )
  }

  displayPage(){
   
      return(
<Container style={{ paddingBottom: 20, width: "100%" }}>
          <Row>
            <Col>
              <h1>Book Now</h1>
            </Col>
            <p style={{float:"left"}}>
            Select a date and complete the form. Once we've recieved your request we'll be in touch to secure your dates, how to make payment and confirm the booking.<br/>
            We hate spam as much as you, so we'll keep your data secure and never pass it on to any third parties
            </p>
          </Row>
          {this.displayBookingForm()}
        </Container>
      )
  }
  render() {
    return (
      <div className="App">
      {this.displayPage()}

        
      </div>
    );
  }
}