import React, { Component } from 'react';
import {Row, Col, Button} from 'reactstrap';
import {postData} from '../scripts/PostData';
export default class DateManagerTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dates:[],
        }
    }
    isActive = (status) => {
        if (status) {
            return "Blocked"
        }
        else {
            return "Inactive"
        }
    };
    componentWillMount(){
        this.setState({dates:this.props.dates})

    }
    async getDates(){
        const that = this;
        await fetch("finesse/GetBlockedDates.php")
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (jsonStr) {
            that.setState({ dates: jsonStr}, function () { });
          }).catch(error => { console.log('request failed', error); });
      }

    componentWillReceiveProps(nextprops){
       this.setState({dates:nextprops.dates});
    }

    convertDate(date) {
        var d = new Date();
        var b = new Date(date).toLocaleString('en-GB');
        
        d.setTime((date*1000));
        return (
            b.substring(0, b.toLocaleString('en-GB').indexOf(','))
        )
    }

    DeleteDocument = (collection, documentId) => {
        
        var data = {
            key: documentId,
            table: collection,
        }
        postData(JSON.stringify(data), 'finesse/DeleteFromDatabase.php').then(event =>{this.getDates()});
    };

    render() {
        return (
            <Row>
                <Col>
                    <table id="dateTable" style={{ fontSize: 15 }}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Blocked</th>
                                <th>Comments/Reasons</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dates.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{this.convertDate(data.date)}</td>
                                        <td>{this.isActive(data.status)}</td>
                                        <td>{data.comments}</td>
                                        <td><Button onClick={(event) => {this.DeleteDocument('blockedDates', data.Key); }}>Delete</Button>
                                             </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <br />
                    <br />
                </Col>
            </Row>
        )
    }
}