import fetch from 'isomorphic-fetch';

export function postData(data,url) {
return fetch(url, {
    method: 'POST',
    body: data,
    headers: {
        'Content-Type': 'application/json'
    },
    redirect: "follow", 
}).then(response => {
    if (response.status >= 200 && response.status < 300) {
        return response.json;
      } else {
          alert('Something went wrong. Try again, if it fails, email Matt!')

      }
})}