import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
  } from 'react-accessible-accordion';
export default class BlankComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }




    render() {
        return (
            <div>
 <h1>FAQS</h1>
 <Row>
     <Col>
     
        <Accordion>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long do you have to wait for the photo to print?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    As we use the latest technology, our prints are usually printed within 10 seconds for you to enjoy and take away.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I reserve a Magic Mirror for an event?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Once you have decided you would like to book by completing our booking form and the date has been confirmed, 
                    an invoice will be sent to you via email. 
                    A NON refundable deposit of £50 will secure your date with the balance due 30 days before your event. 
                    You are also welcome to pay in full when booking.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I need special power or leads for the equipment to be set up?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    A normal power source is all that is needed, preferably within 3-6 metres
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do you have public liability insurance?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we have 10 Million Public & Product liability insurance, if you require a copy of our policy please speak to a member of the team.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is the equipment PAT tested?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes all are equipment is PAT tested, if you require a copy of our certificate please ask
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do you supply an attendant?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes all equipment comes with a fully trained attendant.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does the magic mirror work?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    By touching the screen, guests will activate the magic mirror; they will be guided through the fun animations, 
                    and prompted to write a message or add fun emoji's directly onto the screen, 
                    once they have gone through this their pictures will be displayed for them to print.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do we get an instant print?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes all prints are instant.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Who Are You?
                </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We are two good friends from Staffordshire that both love all things photography and capturing memories 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How Many Prints Are Included?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Photographs & prints are truly unlimited for the full duration of your hire time. 
                    For as many times as your guests want to use them then the printer keeps printing! 
                    Each guest will get a copy of their time posing each & every time. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can We Personalise Our Prints?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Absolutely! We will happily customise your chosen photo strip with a message / names / dates for free! 
                    We will also work within your colour scheme too! We have a huge selection of amazing customisable photo strips to choose from. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can the Magic Mirror Go Upstairs? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The Mirror can be taken upstairs  as long as there is a lift or adequate disabled access. 
                    The Magic Mirror is large & very heavy so cannot be lifted upstairs for health & safety reasons.  
                    It is the persons that is booking the mirror responsibility to make sure that there is a way for us to be 
                    able to get to another floor with the Magic Mirror. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What value does MAGIC MIRROR PHOTO BOOTH of NC add to my party?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    They are incredibly fun and entertaining for you and your guest to use and they create amazing memories of your special event. 
                    As soon as your guests see that there is a magic mirror at your event, there is no stopping the fun. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to set up your photo booth?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on location and layout of venue, it should take about 45-60 minutes. 
                    We will work with you to find the best possible location within your venue to set up the Magic Mirror for optimium traffic flow and fun!
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How close to my event should I book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Bookings are taken well in advance, therefore to secure your date we would advise you to book as soon as your event is confirmed. 
                    Keep in mind we are very busy during peak season and weekends. We will always try to accommodate last minute bookings depending on availability.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
        </Col>
 </Row>
        </div>
        )
    }
}