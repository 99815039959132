import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import {db} from '../components/firebase.js';
import {postData} from '../scripts/PostData.js';

export default class TestimonialUploader extends Component {
    constructor(props) {
        super(props);

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.state = {
            testimonialName: "",
            testimonialText: "",

        }
    }

    onTestimonialFormSubmit() {
        var data = {
          ClientName:this.state.testimonialName,
          TestimonialText:this.state.testimonialText,
          Status:true
        }
        db.collection('testimonials').doc().set(data);
       }
    
       onFormSubmit() {
        var data = {
          ClientName:this.state.testimonialName,
          TestimonialText:this.state.testimonialText,
          Status:true,
        }
        postData(JSON.stringify(data),'finesse/AddTestimonial.php').then((response) => {this.props.action()});;
        this.setState({testimonialName:"",testimonialText:""})
       }


    render() {


        return (
            <Row>
                <Col>
                    <Form inline>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Label for="clientName" className="mr-sm-2">Enter Testimonial Client Name</Label>
                            <Input type="text" name="clientName" id="clientName" value={this.state.testimonialName} placeholder="Enter Text..." onChange={e => this.setState({ testimonialName: e.target.value })} />
                        </FormGroup>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Label for="testimonialText" className="mr-sm-2">Enter Testimonial</Label>
                            <Input type="textarea" style={{height:150, maxWidth:500}} value={this.state.testimonialText} name="testimonialText" id="testimonialText" placeholder="Enter Text..." onChange={e => this.setState({ testimonialText: e.target.value })} />
                        </FormGroup>
                        <Button type="button" onClick={this.onFormSubmit}style={{marginLeft:10}}>Submit</Button>
                    </Form>
                </Col>
            </Row>
        )
    }
}