import React, { Component } from 'react';
import '../App.css';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';



class Termsandconditions extends Component {
   constructor(props) {
    super(props);
    this.faqs = React.createRef();
    this.prices = React.createRef();
    this.mmse = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
    
  }


  scroll(ref) {
    ref.current.scrollIntoView({behavior: 'smooth',block: "center"})
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className="App">
        <Container>
          <Row>
            <Col>
        <div style={{textAlign:"left"}} >
          <h1>Terms and Conditions</h1>
          
Booking/Enquiries Terms and Conditions<br/><br/>
DEFINITIONS AND INTERPRETATION<br/><br/>
In this Agreement unless the context requires otherwise the following words and expressions have the following meanings:<br/>
‘Contact’ means the contact named by the Hirer on the Booking form. Please note that if on the day the Contact is not available then Finesse Photos (Visionary Events Limited) reserves the right to select an appropriate alternative. This may include, but not be limited to, a member of staff at the Venue, an events coordinator, a User or a close family member or friend of the Hirer or indeed the Hirer themselves.<br/>
Booking form’ means the form completed to confirm details of booking including Contact on the day. This may also be in the form of an e-mail. ‘Hirer’ means the Main booking contact named on the Initial Booking Form. ‘Hire date’ means the date the Magic Mirror is hired.<br/>
‘Magic Mirror’ means the “Finesse Photos” (Visionary Events Limited)  system and related equipment. ‘Delivery note means the form completed on the Hire date which is signed by the Contact on the day and the Finessephotos.co.uk (Visionary Events Limited) representative. ‘Location’ means the Location of the Magic Mirror once delivered and setup by a Finesse Photos  (Visionary Events Limited) Representative on the Hire date. ‘User’ means the people that use the Magic Mirror whilst out on hire to the Hirer. ‘Venue’ means the postal address at which the Magic Mirror is to be located on the Hire date. ‘Finesse Photos   (Visionary Events Limited) Representative’ means a representative, either employed directly or sub-contracted for Finesse Photos (Visionary Events Limited) .<br/>
In this Agreement the words ‘include’, ‘includes’, ‘including’ and ‘such as’ are to be construed as if they were immediately followed by the words ‘without limitation’.<br/>
Reference to the singular includes the plural and vice versa.<br/>
Finesse Photos may use pre-vetted agents for event hire. Systems and Mechanical Structures are identical to Finesse Photos equipment in each way. Finesse Photos can not be held responsible for any damage from third parties. All complaints need to be directed to the third party hire for reconciliation.<br/>
<br/>COMMENCEMENT<br/><br/>
The Agreement shall commence on the date the Booking Form is signed or confirmation of booking is received via e-mail and shall remain in place until the Magic Mirror is either collected or returned to the satisfaction of The Finesse Photos (Visionary Events Limited) .<br/>
<br/>USE OF THE MAGIC MIRROR PHOTO BOOTH<br/>
The Hirer is to ensure the Magic Mirror is used properly and ensure that it will be used safely and without risk to health, shall not remove or alter or permit removal or alteration of identification numbers or marks of the Magic Mirror, and shall keep the Magic Mirror properly protected and safe. Under no circumstances shall The Finesse Photos (Visionary Events Limited) have any liability of whatever kind for any defects resulting from wear and tear, accident, improper use by the Hirer or Users except in accordance with the instructions or advice from The Finesse Photos (Visionary Events Limited) .<br/>
The Hirer is to ensure the Magic Mirror shall not be moved from both the Location and Venue other than by a Finesse Photos (Visionary Events Limited) Representative.<br/>
The Hirer is to obtain any consent or permissions in the use of the Magic Mirror at the Venue and the Finesse Photos  (Visionary Events Limited)  shall not be held liable for refusal or lack of any consent or permission.<br/>
The Magic Mirror shall at all times remain the property of the respective Finesse Photos  (Visionary Events Limited)  .<br/>
The Magic Mirror shall be delivered on date of Hire at which time the Finessephotos.co.uk  (Visionary Events Limited)  Representative will leave the Photo Booth operational and in suitable condition for use. The Contact on the day, or, agreed alternative Contact will sign or agree verbally the Magic Mirror Deliver Note to confirm they agree the Magic Mirror is operational and not damaged.<br/>
The Finesse Photos  (Visionary Events Limited) Representative has the right to refuse to deliver the Photo Booth if:<br/>
It believes that the Magic Mirror will not be used properly;<br/>
The Venue or Location is believed to be unsafe or a risk to health;<br/>
The Venue or Location is believed to possibly cause damage to the Magic Mirror;<br/>
The Venue or Location is believed to not be secure;<br/>
The Contact on the day cannot be found to affirm the Magic Mirror Delivery Note;<br/>
If The Contact on the day refuses to sign the Magic Mirror Delivery Note;<br/>
The Contact on the day has the right to refuse to accept the delivery of the Magic Mirror if:<br/>
They believe the Magic Mirror to be unsafe or a risk to health;<br/>
The Magic Mirror is damaged to the point of being unusable;<br/>
The Magic Mirror arrives outside of the delivery timings listed on the Booking form.<br/>
After initial setup the Booth cannot be moved. Moving the magic mirror will incur an additional charge.<br/>
We have taken every care to ensure that all items on our website & social media are correct. We will not be held liable for any errors or inaccuracies which may occur through the website, social media,  email or verbally with a representative and will not be obliged to supply goods at any inaccurate price.<br/>
We endeavour to be timely for setup and the start of the event, however due to certain circumstances beyond our control we may be delayed. Where possible an attendant will contact the persons responsible to notify them. We will endeavour to run the magic mirror over the allotted time to make up the correct hire time if possible. We cannot give any refunds for circumstances beyond our control such as parking or distances for transferring the equipment.<br/>
Extra Print Offers are for Normal/Wedding Album fills only. After the Album has been deemed to be completed by our Attendant the booth may be switched back to single print mode.<br/>
Green Screen Use requires the replacement of Green colouring to work, we cannot be held responsible if persons are wearing green whilst using the booth.<br/>
<br/>
<br/>
GUEST BOOK<br/>
Guest books are provided for guests to put their second photo in the book with a message, we do actively encourage this but cannot be held responsible for guests not leaving the photo or leaving a message or leaving the photo and message in a funny position. The guest book is left mainly for guest to use throughout the night and cannot be manned by the attendant all night.<br/>
<br/>
PAYMENT<br/>
A deposit as specified upon the Booking form shall be paid by the Hirer to the Finesse Photos  (Visionary Events Limited) . A receipt in the form of an e-mail shall be sent to the Hirer. The receipt is the only proof of booking that the Hirer shall receive. 4.2. An invoice in respect of hire will be issued no later than 6 weeks prior to the date of the event and FULL payment must be made no later than 4 weeks prior to the Hire date.<br/>
If the Hirer fails to pay the invoice price by the date due then the Finesse Photos  (Visionary Events Limited)  has the right to cancel the booking with no deposit being returned to the Hirer.<br/>
No refunds are to be given due to fault of the Hirer in operation of, or use of, the Magic Mirror, or in lack of obtaining, or in the refusal of the appropriate consents or permissions. No refund will be made for low usage of the facility.<br/>
<br/>
CANCELLATION<br/>
Upon receipt of written notification of cancellation four weeks prior to Hire date Finesse Photos  (Visionary Events Limited) shall refund any monies paid less the Deposit.<br/>
Upon receipt of written notification within four weeks prior to Hire date no refund will be provided.<br/>
<br/>DAMAGE LOSS AND REPAIR<br/>
The Hirer shall be responsible for the loss, theft or destruction of or for any damage to the Magic Mirror occasioned in any manner or by whosoever or by any cause whatsoever while the Magic Mirror is at the Hirer’s risk (other than as a result of fair wear and tear) and fully and effectually indemnify the Finesse Photos.  (Visionary Events Limited) in respect of all claims, proceedings, costs, expenses, loss, damage and liabilities incurred by the Finesse Photos  (Visionary Events Limited) arising directly or indirectly form any such loss, theft, destruction or damage.<br/>
Finessephotos.co.uk  (Visionary Events Limited) shall not be responsible for any losses or equipment failure which is beyond its control.<br/>
<br/>MECHANICAL PROBLEMS<br/>
On the rare occasion the Magic Mirror may have technical problems every effort will be made to rectify the problem. If the problem is rectified the magic mirror will made available for use over the time provided, if the problem is a printer problem photos will still be taken and photos will be printed out the next working day and sent to the client. No Refund shall be given unless the Magic Mirror cannot take photos from a camera failure or hard drive failure.<br/>
<br/>TERMINATION ON DEFAULT, ETC<br/>
Without prejudice to the provision of clause 4.4 the Finessephotos.co.uk  (Visionary Events Limited) may by written notice (in addition to any other rights) terminate this Agreement or suspend its performance of all or any of its obligations under the same immediately and whether for compensation or damages or otherwise if the Hirer fails to comply with any of its obligations under the terms of this Agreement.<br/>
<br/>ENTIRE AGREEMENT<br/>
This Agreement expresses the entire agreement between of the Parties in respect of the matters contained or referred to in it and there are no promises, terms, conditions or obligations oral or written express or implied other than those contained herein.<br/>
No warranties, representations or other matters were relied upon by the Hirer causing it to enter into this Agreement other than those expressly set out herein.<br/>
This Agreement shall not otherwise be modified in any way except through the agreement by the Parties.<br/>
Except in the case of fraudulent misrepresentation the only remedy available to the Hirer in respect of any misrepresentation or untrue statement made to it shall be a claim for breach of contract.<br/>
If in the event that the Photo Booth cannot be deliverd due to mechanical failure or transportation, or the photo booth malfunctions to a point of being unusable to record photos or videos Photobooth.co.uk (Global Photo Booths Limited) will be liable for a full refund of the booking amount only.<br/>
<br/>FORCE MAJEURE<br/>
The Finessephotos.co.uk  (Visionary Events Limited) shall not be liable for any failure in the performance of any of its obligations under this Agreement caused by, and may suspend performance of the same during, factors outside its control including, but not limited to, disorder and natural disasters.<br/>
<br/>THIRD PARTIES<br/>
The Parties do not intend that any term of this Agreement may be enforced by a third party as defined in the Contracts (Rights of Third Parties) Act 1999 under the provisions of that Act.<br/>
<br/>NOTICES<br/>
Any notice required to be given for the purposes of this Agreement must be given by registered or recorded delivery mail.<br/>
The Hirer’s address for the service of notice shall be the address stated on<br/>
the Booking Form. Any changes of address must be notified and acknowledged to be deemed as valid.<br/>
A notice shall be deemed to have been serviced if a notification of completed recorded delivery notice is provided via royal mail.<br/>
The Hirer acknowledges and accepts all materials (video, photo and text messages) recorded on the day can be used by Finessephotos.co.uk  (Visionary Events Limited) for the purpose of promoting and advertising its products and services. Furthermore the Hirer does not hold Finessephotos.co.uk  (Visionary Events Limited) liable for any issues arising through the use of such material.<br/>
<br/>COPYRIGHT PHOTOS & VIDEOS<br/>
Finessephotos.co.uk  (Visionary Events Limited) will own the copyright of all images and videos taken using the Magic Mirror Photo booth. We reserve the right to send images to third parties. Photos will be stored via server and online. USBs are sometimes sent to customers via postal service, Finesse Photos  (Visionary Events Limited) cannot be held responsible for Photo graphs/Video being given or sent to customer for loss or damage to the USBs in transit or at the event. After an event is completed, the photos from it are only kept on the hardware system in the booth for one month. Finesse Photos have the right to use photos/videos from events for website or social media purposes unless requested otherwise by the hirer.<br/>
<br/>CHOICE OF LAW AND JURISDICTION<br/>
This contract shall be governed by English law and subject to the exclusive jurisdiction of courts of England and Wales in all matters regarding it except to the extent that the Finessephotos.co.uk  (Visionary Events Limited) invokes the jurisdiction of the courts of any other country.<br/>

<br/>Website Terms and Conditions<br/>
Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Photobooth.co.uk’s relationship with you in relation to this website.<br/>
The term ‘Finesse Photos’ or ‘us’ or ‘we’ refers to the owner of the website whose registered office is:<br/>
Visionary Events Limited<br/>
United Kingdom<br/>
Contact: sales@finessephotos.co.uk<br/>
Our company registration number is 11526910 – registered in England. The term ‘you’ refers to the user or viewer of our website.<br/>
The use of this website is subject to the following terms of use:<br/>
The content of the pages of this website is for your general information and use only. It is subject to change without notice.<br/>
Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.<br/>
Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.<br/>
This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.<br/>
All trade marks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.<br/>
Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.<br/>
From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).<br/>
You may not create a link to this website from another website or document without finessephotos.co.uk prior written consent.<br/>
Your use of this website and any dispute arising out of such use of the website is subject to the laws of England, Scotland and Wales.<br/>
Use of the Booking System or Enquiries will mean your details will be shared within our network of Magic Mirror operators <br/>
        </div>
        </Col>
        </Row>
        
        </Container>




      </div>

    );
  }
}

export default Termsandconditions;
