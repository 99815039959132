import fetch from 'isomorphic-fetch';

export function postDataResponse(data,url) {
return fetch(url, {
    method: 'POST',
    body: data,
    headers: {
        'Content-Type': 'application/json'
    },
    redirect: "follow", 
}).then(function(response) {
    return response.json();
}).then(function(jsonData) {
    return jsonData;
})
}