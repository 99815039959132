import React, { Component } from 'react';
import '../App.css';
import {
  Container,
  Row,
  Col,
  Spinner
} from 'reactstrap';
import { db } from '../components/firebase';
import "react-alice-carousel/lib/alice-carousel.css";

export default class ImageGallery extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    this.book = React.createRef();
    this.state = {
      isOpen: false,
      albums: [],
      albumnInfo:[],
      loading: true
    };
    this.displayAlbum = this.displayAlbum.bind(this);
  }
  async getAlbums() {
    db
      .collection('albums')
      .get()
      .then(collection => {
        var albums = [];
        albums = collection.docs.map(doc => doc.data())
        const ids = collection.docs.map(doc => doc.id)
        this.setState({ ids }, function () { })
        var final = [];
        var finalArr = [];
        var i;
        for (i = 0; i < albums.length; i++) {
          var Dataarray = [];
          Dataarray = albums[i];
          var IdArray = [];
          IdArray = ({ "key": ids[i] });
          final = { ...IdArray, ...Dataarray };
          finalArr.push(final);
        }
        this.setState({ albums: finalArr }, function () { this.setState({ loading: false }) })
      })
  }

  getAlbumsFromDb = async () => {
    const that = this;
    await fetch("finesse/GetAlbums.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        that.setState({ albumnInfo: jsonData, galleryLoaded:true}, function () { });
        that.setPromoData(jsonData);
        return jsonData;
      })
      .catch(error => { console.log('request failed', error); });;
  }


  componentWillMount(){
    this.setState({authenticated:this.props.authenticated});
  }
  componentDidMount() {
    this.getAlbums();
    this.getAlbumsFromDb();
  }
  scroll(ref) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: "center" })
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  displayAlbum(albumName, key) {
    console.log()
    this.props.history.push({
      pathname: '/album',
      state: { album: albumName, albumKey: key }
    })
  }

  displayPage(){
   
      if(this.state.galleryLoaded){
      return(
<Container>
          <Row>
            <Col>
              <h3>Take a look at some of the fantastic events that we've been a part of</h3>
            </Col>
          </Row>
          <Row>

            {this.state.albumnInfo.map((data, i) => {
              return (
                <Col key={i}style={{ paddingBottom: 20 }}>
                  <div>
                    <img alt={i} src={data.AlbumImage} style={{ cursor: "pointer",minHeight:150, maxHeight: 150 }} onClick={() => this.displayAlbum(data.AlbumName, data.Key)} />
                    <div className="caption">{data.AlbumName}</div>
                  </div>
                </Col>
              )
            })}

          </Row>
          <Row>

          </Row>
        </Container>
      )
    }
  }

  render() {
    return (
      <div className="App">
{this.displayPage()}
        
      </div>

    );
  }
}
