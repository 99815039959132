import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import {postData} from '../scripts/PostData';


export default class AlbumTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonialName: "",
            testimonialText: "",
            testimonials: [],
            apiInfo: [],
        }
    }

    componentWillMount(){
        this.setState({ apiInfo: this.props.apiInfo });
      }

    componentDidMount() {
        this.getAlbumsFromDb();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ apiInfo: nextProps.apiInfo });
    }

    getAlbumsFromDb = async () => {
        const that = this;
        await fetch("finesse/GetAlbums.php")
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (jsonStr) {
            that.setState({ apiInfo: jsonStr, albumsLoaded: true }, function () { });
          }).catch(error => { console.log('request failed', error); });;
      }

      DeleteDocument = (collection, documentId) => {
        var data = {
            key: documentId,
            table: collection,
        }
        postData(JSON.stringify(data), 'finesse/DeleteFromDatabase.php').then((response) => {this.props.action()});;
        this.getAlbumsFromDb();
    };
    isActive = (status) => {
        if (status) {
            return "Active"
        }
        else {
            return "Inactive"
        }
    };

    GetButtonText(val) {
        if (val) {
            return "Set To Inactive"
        }
        else {
            return "Set To Active"
        }
    }

    async SetStatus(val, AlbumName, BlogText, AlbumImage, key) {
        
        var data = {
            Key:key,
            AlbumName: AlbumName,
            AlbumImage:AlbumImage,
            BlogText: BlogText,
            Status: !val,
        }
        await postData(JSON.stringify(data),"finesse/UpdateAlbums.php")
        return this.getAlbumsFromDb();
   
}


    render() {
        return (
            <Row>
                <Col>
                    <table id="albumsTable" style={{ fontSize: 15 }}>
                        <thead>
                            <tr>
                                <th>Album Name</th>
                                <th>Blog Text</th>
                                <th>Album Image</th>
                                <th>Status</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.apiInfo.map((data, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{data.AlbumName}</td>
                                        <td>{data.BlogText}</td>
                                        <td><img
                                            style={{ maxHeight: 100 }}
                                            src={data.AlbumImage}
                                            alt="AlbumImage"
                                        /></td>
                                        <td>{this.isActive(data.Status)}</td>
                                        <td><Button onClick={() => this.DeleteDocument('albums', data.Key)}>Delete</Button> <br /> <br />
                                            <Button onClick={() => this.SetStatus(data.Status, data.AlbumName, data.BlogText,data.AlbumImage, data.Key)} value={this.GetButtonText(data.Status)}>{this.GetButtonText(data.Status)}</Button> </td>
                                    </tr>
                                )
                            })}
                        </tbody>


                    </table>
                </Col>
            </Row>
        )
    }
}