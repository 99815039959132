import React, { Component } from 'react';
import './App.css';
import Routes from "./Routes";
import { withRouter } from 'react-router-dom';
import 'react-accessible-accordion/dist/fancy-example.css';
import NavbarThing from './components/Navbar.js'
import Crouton from 'react-crouton';
import firebase, { db } from './components/firebase';
import { Link } from "react-scroll";
import { Link as PageLink } from 'react-router-dom';
import up from './images/icons8-slide-up-50.png';
import fb from './images/icons8-facebook-old-48.png';
import inst from './images/icons8-instagram-48.png';
import { postDataResponse } from './scripts/PostDataResponse';
import { loadReCaptcha } from 'react-recaptcha-v3'

class App extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.displayAvailablePromotion = this.displayAvailablePromotion.bind(this);
    this.state = {
      isOpen: false,
      promotionAvailable: true,
      promotionMessage: "abc",
      finalArr: [],
      testimonialInfo:[],
      loading: false,
    };
  }

  getPromotionsFromDb = async () => {
    const that = this;
    await fetch("finesse/GetPromotions.php")
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonData) {
        that.setState({ testimonialInfo: jsonData}, function () { });
        that.setPromoData(jsonData);
        return jsonData;
      })
      .then(function (jsonStr) {
      
  
      }).catch(error => { console.log('request failed', error); });;
  }


  async getPromotions() {
    var promotionsRef = db.collection('promotions');

    promotionsRef.where('Status', '==', true)
      .get()
      .then(collection => {
        var promotions = [];
        promotions = collection.docs.map(doc => doc.data())
        const ids = collection.docs.map(doc => doc.id)
        this.setState({ ids }, function () { })
        var final = [];
        var promofinalArr = [];
        var i;
        for (i = 0; i < promotions.length; i++) {
          var Dataarray = [];
          Dataarray = promotions[i];
          var IdArray = [];
          IdArray = ({ "key": ids[i] });
          final = { ...IdArray, ...Dataarray };
          promofinalArr.push(final);
        }
        this.setState({ promotions: promofinalArr }, function () { })
        if (promofinalArr.length > 0) {
          this.setState({ promotionAvailable: true }, function () { this.setPromoData(promofinalArr) })
        }
        else {
          this.setState({ promotionAvailable: false })
        }
      })
  }
  async checkDbForSession(e){
    var data = {
      sessionKey : e
    }
    await postDataResponse(JSON.stringify(data), 'finesse/getSession.php');

  }

  componentWillMount() {
    this.getPromotionsFromDb();
    this.listener = firebase.auth().onAuthStateChanged((authenticated) => {
     if(authenticated){
       this.setState({authenticated:true});
       this.props.history.push({
       authenticated:this.state.authenticated
      })
     }
     }
    
    );
}

  setPromoData(finalArr) {
    var message = finalArr[0]["promotionDetails"];
    var backgroundColor = finalArr[0]["promotionBackgroundColour"];
    var fontColor = finalArr[0]["promotionFontColour"]
    this.setState({ promotionMessage: message, backgroundColor: backgroundColor, fontColor: fontColor, loading: true })
  }
  componentDidMount() {
    loadReCaptcha('6LcddqoUAAAAAJMJPUp-FZYWoLRfNxuRoP92g7pp');
  }

  checkForAvailablePromotions() {
    //TODO: if count of promotions > 1 return true
  }

  setPromotionalInfo() {
    if (this.state.promotionAvailable) {

    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  listener() {
 
  }
  displayAvailablePromotion() {
    if (this.state.promotionAvailable) {
      return (
        <Crouton
          message={this.state.promotionMessage}
          type="info"
          id= {Date.now()}
          buttons="close"
          onDismiss= {this.listener}
          children={true}
        ><div style={{ background: `${this.state.backgroundColor}`, color: `${this.state.fontColor}` }}>{this.state.promotionMessage}</div></Crouton>
      )
    }
  }
  displayLocalStorage(){
    if(localStorage.getItem('authenticated')){
      return(
        <div>
          true!
        </div>
      )
    }
  }



  render() {

    const childProps = {
authenticated : this.state.authenticated
    };
    //   var mmse = ReactDOM.findDOMNode(this.refs.mmse)
    return (
      <div className="App">
        <div className = "body-content">
        <div>
          {this.state.loading ? (
            this.displayAvailablePromotion()
          ) : null}

   
        <NavbarThing authenticated={this.state.authenticated} />
     
        <Routes childProps={childProps} />

        </div>
        <footer className="footer" style={{backgroundColor: "#e9e9e9" }}>
          <PageLink to="/termsandconditions">Terms and Conditions</PageLink><br />

          Copyright Finesse Photos 2019 Company Registration Number 11526910           <Link
              to={'magic-mirror'}
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={this.toggle}
              style={{cursor:'pointer'}}
            >
            <img alt="back to top" style={{float:"right"}} src={up}/></Link><br/>
            <a href="https://www.facebook.com/FinessephotoUK/"><img alt="facebook" src={fb} style={{height:48, width:48}}/></a>
          <a href="https://www.instagram.com/finessephotosuk/"><img alt="instagram" src={inst} style={{height:48, width:48}}/></a>
        </footer>

        </div>

      </div>

    );
  }
}

export default withRouter(App);
