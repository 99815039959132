import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import BookOrderIn from './BookOrderIn.js';
import { db } from './firebase';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import HistoricBookingTable from './HistoricBookingTable.js';
import Book from '../containers/Book.js';

export default class BookingTable extends Component {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.displayHistoricTable = this.displayHistoricTable.bind(this);
        this.displayBookingForm = this.displayBookingForm.bind(this);
        this.state = {
            bookedDates: this.props.bookedDates,
            apiInfo: [],
            products: [],
            historicTableData:[],
            displayHistoric: false,
            displayBookingForm: false,
        }
    }

    componentWillMount() {
        this.setState({ apiInfo: this.props.apiInfo, products: this.props.apiInfo })
        this.EditData(this.props.apiInfo);
        this.getHistoricBookings();

    }

    componentWillReceiveProps(nextProps){
        this.setState({apiInfo:nextProps.apiInfo}); 
        this.EditData(nextProps.apiInfo);
    }

    EditData(data) {
        if (data != null) {
            var i;
            var dataItem = [];
            for (i = 0; i < data.length; i++) {
                dataItem = data[i];
                var newDate = this.updateDate([dataItem['date']])
                dataItem['date'] = newDate;

            }
        }
        this.setState({ products: data });
    }

    updateDate(date) {
        
        var d = new Date();
        d.setTime(date);
        return (
            d.toLocaleString('en-GB').substring(0, d.toLocaleString('en-GB').indexOf(','))
        )
    }
    convertDate(date) {
        var d = new Date();
        d.setTime(date);
        return (
            d.toLocaleString('en-GB').substring(0, d.toLocaleString('en-GB').indexOf(','))
        )
    }

    isActive = (status) => {
        if (status) {
            return "Booked"
        }
        else {
            return "Awaiting customer call"
        }
    };
    
    getStatus(s) {
        
        if(typeof s != 'undefined'){
            
          switch(s){
              
              case "Awaiting Customer Call":
                  return "Awaiting Customer Call";
              case "Cancelled":
                  return "Cancelled Order";
              case "Booked":
                  return "Order Booked";
              case "":
                  return "Awaiting Customer Call";
              case "AFI":
                  return "Awaiting Further Information";
              default:
                  return "Awaiting Further Information";
          }
        }
    }

    getId(s){
        return "FP" + s
    }
    DeleteDocument = (collection, documentId) => {
        db.collection(collection).doc(documentId).delete();
        this.getDates();
    };
    updateData=() => {
        this.props.action();
    }

    closeNewOrder=() =>{
        this.setState({displayBookingForm:false})
    }

    displayHistoricTable(){
        this.setState({displayHistoric:true, displayBookingForm:false})
    }

    displayBookingForm(){
        this.setState({displayHistoric:false, displayBookingForm:true})
    }
    getHistoricBookings = async() =>{
        const that = this;
        await fetch("finesse/GetBookedDatesOlder30Days.php")
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (jsonStr) {
            that.setState({ historicTableData: jsonStr, bookedDatesLoaded: true }, function () { });
          }).catch(error => { console.log('request failed', error); });
    };

    


    histroicTable(){
        if(this.state.displayHistoric)
        {
            return(
                <div>
                <HistoricBookingTable apiInfo={this.state.historicTableData}/>
                </div>
            )
            
        }
        if(this.state.displayBookingForm)
        {
            return(
                <div>
                    <Book arrivalMethod={"admin"} authenticated={true} close={this.closeNewOrder} action={this.updateData}/>
                </div>
            )
        }
    }

    render() {

        return (
            <div>
                <ReactTable
                    data={this.state.products}
                    columns={[
                        {
                            Header: "Booking Details",
                            columns: [
                                {
                                    Header: "Booking Id",
                                    id:"bookingId",
                                    accessor: d => this.getId(d.bookingId),
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Booking Id</div>
                                },
                                {
                                    Header: "Date",
                                    accessor: "date",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Date</div>
                                },
                                {
                                    Header: "Full Name",
                                    accessor: "fullName",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Full Name</div>
                                },
                                {
                                    Header: "Location",
                                    accessor: "location",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Location</div>
                                },
                                {
                                    Header: "Email",
                                    accessor: "email",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Email</div>
                                },
                                {
                                    Header: "Telephone",
                                    accessor: "telephone",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Telephone</div>
                                },
                                {
                                    Header: "Order Status",
                                    id: "status",
                                    accessor: d => this.getStatus(d.booked),
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Order Status</div>
                                },
                                {
                                    Header: "Cx Comments",
                                    accessor:"comments",
                                    Footer: () =>
                                        <div style={{ textAlign: "center" }}>Cx Comments</div>
                                }
                            ]
                        },
                        {
                            Header: "Package",
                            columns: [
                                {
                                    Header: "Package",
                                    accessor: "package",
                                    Footer: () => <div style={{ textAlign: "center" }}>Package</div>
                                }
                            ]
                        },
                        {
                            Header: "Expand",
                            columns: [
                                {
                                    expander: true,
                                    Header: () => <strong>More</strong>,
                                    width: 65,
                                    Expander: ({ isExpanded, ...rest }) =>
                                        <div>
                                            {isExpanded
                                                ? <span>&#x2299;</span>
                                                : <span>&#x2295;</span>}
                                        </div>,
                                    style: {
                                        cursor: "pointer",
                                        fontSize: 25,
                                        padding: "0",
                                        textAlign: "center",
                                        userSelect: "none"
                                    },
                                    Footer: () => <span>&hearts;</span>
                                }
                            ]
                        }
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    SubComponent={(v) => <BookOrderIn data={v.row} action={this.updateData} />}
                />
                <Row>
                    <Col md={6}>
                    <Button onClick={this.displayHistoricTable}>View Historic Bookings</Button>
                    </Col>
                    <Col md={6}>
                    <Button onClick={this.displayBookingForm}>New Order</Button>
                    </Col>
                </Row>
                {this.histroicTable()}
            </div>
        )
    }
}