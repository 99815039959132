import React, { Component } from 'react';
import {Input, Button, Row, Col } from 'reactstrap';
import {postData} from '../scripts/PostData';
import { db } from '../components/firebase.js';

export default class TestimonialTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonialName: "",
            testimonialText: "",
            tesimonialStatus: true,
            testimonials:[],
            editRow:"",
            apiInfo: [],
        }
    }

    getTestimonialsFromDb = async () => {
        const that = this;
        await fetch("finesse/GetTestimonials.php")
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonData) {
            return jsonData;
          })
          .then(function (jsonStr) {
            
            that.setState({ apiInfo: jsonStr}, function () { });
          }).catch(error => { console.log('request failed', error); });;
      }

    componentWillMount(){
        this.setState({ apiInfo: this.props.apiInfo });
      }

    componentDidMount(){
          
      }

    componentWillReceiveProps(nextProps) {
        this.setState({ apiInfo:nextProps.apiInfo });  
      }
    DeleteDocument = (collection, documentId) => {
        var data = {
            key: documentId,
            table: collection,
        }
        postData(JSON.stringify(data), 'finesse/DeleteFromDatabase.php').then(event =>{this.getTestimonialsFromDb();});
        
    };
    isActive = (status) => {
        if (status) {
            return "Active"
        }
        else {
            return "Inactive"
        }
    };

    GetButtonText(val) {
        if (val) {
            return "Set To Inactive"
        }
        else {
            return "Set To Active"
        }
    }

    SetStatus(collection, val, id) {
        if (val) {
            db.collection(collection).doc(id).update({
                Status: false
            });
            return this.getPromotions();
        }
        else {
            db.collection(collection).doc(id).update({
                Status: true
            });
            return this.getPromotions();
        }
    }

    SetRowToEdit(rowKey){
        this.setState({editRow:rowKey})
    }
    submitChanges(rowKey, dbtestimonialText, dbclientName, dbtestimonialStatus){
        this.setState({editRow:""})
        var clientName = this.state.testimonialName;
        var testimonialText = this.state.testimonialText;
        var testimonialStatus = this.state.testimonialStatus;
        if(clientName.length === 0)
        {
            clientName = dbclientName
        }
        if(testimonialText.length === 0){
            testimonialText = dbtestimonialText
        }
        testimonialStatus = dbtestimonialStatus
        this.submitChangesToDb(rowKey,clientName, testimonialText, testimonialStatus, "edit")
        
    }

    submitChangesToDb(rowKey,clientName,testimonialText, testimonialStatus,updateType){
        
            var data = {
              Key:rowKey,
              ClientName:clientName,
              TestimonialText:testimonialText,
              Status:testimonialStatus,
              UpdateType:updateType
            }
            postData(JSON.stringify(data),'finesse/UpdateTestimonial.php').then((response) => {this.props.action()});;
    }
    render() {
        return (
            <Row>
                <Col>
                    <table id="testimonialsTable" style={{ fontSize: 15 }}>
                        <thead>
                            <tr>
                                <th>Client Name</th>
                                <th>Testimonial Text</th>
                                <th>Testimonial Active</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.apiInfo.map((data, i) => {

                                if(this.state.editRow !== data.key)
                                {
                                return (
                                    <tr id={data.key} key={i}>
                                        <td>{data.clientName}</td>
                                        <td>{data.testimonialText}</td>
                                        <td>{this.isActive(data.testimonialStatus)}</td>
                                        <td><Button style={{marginRight:10}} onClick={() => this.DeleteDocument('testimonials', data.key)}>Delete</Button>
                                        <Button style={{marginRight:10}} onClick={() => this.SetRowToEdit(data.key)}>Edit</Button>
                                        
                                            <Button style={{marginRight:10, marginTop:10}} onClick={() => this.submitChangesToDb(data.key,data.clientName,data.testimonialText,!data.testimonialStatus, "updateStatus")} value={this.GetButtonText(data.testimonialStatus)}>{this.GetButtonText(data.testimonialStatus)}</Button> </td>
                                    </tr>
                                )}
                                else{
                                    
                                    return (
                                        <tr id={data.key} key={i}>
                                            <td><Input type="text" name="promoName" id="promoName" defaultValue={data.clientName} onChange={e => this.setState({ testimonialName: e.target.value })} /></td>
                                            <td><Input type="text" name="promoText" id="promoText" defaultValue={data.testimonialText} onChange={e => this.setState({ testimonialText: e.target.value })} /></td>
                                            <td>{this.isActive(data.testimonialStatus)}</td>
                                            <td><Button onClick={() => this.setState({editRow:""})}>Cancel Edit</Button> <br/> <Button style={{marginTop:10}} onClick={() => this.submitChanges(data.key, data.testimonialText, data.clientName, data.testimonialStatus)}>Submit Changes</Button></td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>


                    </table>
                </Col>
            </Row>
        )
    }
}