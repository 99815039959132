import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Input, Label, FormGroup, Spinner, Alert } from 'reactstrap';
import { postData } from '../scripts/PostData';
import { postDataResponse } from '../scripts/PostDataResponse';
import { GetFromDb } from '../scripts/GetFromDb';
import Calendar from 'react-calendar';
export default class BookOrderIn extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.displayCxDetails = this.displayCxDetails.bind(this);
    this.state = {
      data: this.props.data,
      gotBookings: false,
      fullName: this.props.data.fullName,
      location: this.props.data.location,
      Key: this.props.data._original.Key,
      email: this.props.data.email,
      telephone: this.props.data.telephone,
      status: this.props.data.status,
      date: this.props.data.date,
      package: this.props.data.package,
      comments: this.props.data.comments,
      dates: [],
      disabledDatesForCal: [],
      bookedDatesForCal: [],
      dates2: [],
      date3: [],
      newdate: "",
      altTel: "",
      customNotes: "",
      cxAddress: "",
      discount: "",
      paymentType: "",
      resultOfCC: "",
      venueAddress: "",
      venueTel: "",
      finalAmount: "",
      bookingDataLoaded: false,
      emailSent: false
    }
  }
  componentWillMount() {
    this.CheckAndGetEnhancedData();
  }

  async CheckAndGetEnhancedData() {
    var data = {
      bookingId: this.props.data._original.Key
    }
    const result = await postDataResponse(JSON.stringify(data), 'finesse/GetEnhancedBookingInfo.php')

    if (result && result.length) {
      this.populateData(result)
    }
    this.setState({ bookingDataLoaded: true })

  }

  populateData(result) {
    this.setState({ result: result[0] })
    this.setState({
      EnhancedKey: result[0].key,
      altTel: result[0].altTel,
      customNotes: result[0].customNotes,
      cxAddress: result[0].cxAddress,
      discount: result[0].discount,
      paymentType: result[0].paymentType,
      resultOfCC: result[0].resultOfCC,
      venueAddress: result[0].venueAddress,
      venueTel: result[0].venueTel,
      finalAmount: result[0].finalAmount,
      bookingDataLoaded: true
    })
  }

  onSubFormSubmit() {
    var data = {
      Key: this.state.Key,
      Package: this.state.package,
      Date: new Date(this.state.newdate).getTime() + 6000000,
      fullName: this.state.fullName,
      location: this.state.location,
      Email: this.state.email,
      Telephone: this.state.telephone,
      Comments: this.state.comments,
      Booked: this.state.resultOfCC,
      Status: true,
    }

    postData(JSON.stringify(data), '/finesse/UpdateBookedDatesFromBookingForm.php');
    this.setState({ displayCxDetails: false });
    this.props.action();

  }

  async getBlockedDates() {
    await GetFromDb('/finesse/GetBlockedDates.php').then(response => {
      this.setState({ dates2: response });
    }).then(b => {
      console.log();
    })
      .then(e => {
        var disabledDatesArrary = [];
        var final = [];
        var i;
        var dates2 = []
        dates2 = this.state.dates2;

        for (i = 0; i < this.state.dates2.length; i++) {
          var Dataarray = [];
          Dataarray = dates2[i]['date'];
          final = { Dataarray }
          disabledDatesArrary.push(final);

        }
        var j;
        var finalDisabledDates = [];
        for (j = 0; j < disabledDatesArrary.length; j++) {
          var array = [];
          array = disabledDatesArrary[j]['Dataarray'];
          var date = new Date(array);
          finalDisabledDates.push(date)
        }
        this.setState({ disabledDatesForCal: finalDisabledDates })
      }).then(f => {

      });




  }

  async getBookedDatesFromDb() {
    await GetFromDb('/finesse/GetBookedDates.php').then(response => {
      this.setState({ dates3: response });
    }).then(b => {
      console.log();
    })
      .then(e => {
        var bookedDatesForCal = [];
        var final = [];
        var i;
        var dates3 = []
        dates3 = this.state.dates3;

        for (i = 0; i < this.state.dates3.length; i++) {
          var Dataarray = [];
          Dataarray = dates3[i]['date'];
          final = { Dataarray }
          bookedDatesForCal.push(final);

        }
        var j;
        var finalDisabledDates = [];
        for (j = 0; j < bookedDatesForCal.length; j++) {
          var array = [];
          array = bookedDatesForCal[j]['Dataarray'];
          var date = new Date(array);
          finalDisabledDates.push(date)
        }
        this.setState({ bookedDatesForCal: finalDisabledDates })
      }).then(f => {
        this.setState({ gotBookings: true });
      }).catch(error => {
        this.setState({ bookedDateForCal: [], gotBookings: true });
      });
    console.log()
    this.setState({ gotBookings: true });


  }


  async getBlockedOutDates() {
    await this.getBlockedDates()
    await this.getBookedDatesFromDb();


    var arr1 = []
    var arr2 = []
    var arr3 = []
    arr1 = this.state.disabledDatesForCal
    arr2 = this.state.bookedDatesForCal
    arr3 = [...arr1, ...arr2]
    console.log(arr3);
    this.setState({ disabledDatesForCal: arr3 });
    var dateString = this.state.date; // Oct 23

    var dateParts = dateString.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    this.setState({ newdate: dateObject })

  }
  onChange = newdate => this.setState({ newdate })

  displayCalendar() {
    if (this.state.gotBookings) {
      return (
        <div>
          <Calendar tileDisabled={({ date, view }) => this.state.disabledDatesForCal.some(disabledDate => date.getDate() === disabledDate.getDate() && date.getMonth() === disabledDate.getMonth() && date.getFullYear() === disabledDate.getFullYear())
          }
            onChange={this.onChange}
            value={this.state.newdate}
          />

        </div>
      )
    }
    else {
      return (
        <div>
          <Row>
            <Col>
              <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>Please wait, getting available dates</h1>
            </Col>
          </Row>
        </div>
      )
    }
  }

  displayCustomerDetailsToAlter() {
    if (this.state.displayCxDetails) {
      return (
        <Row>
          <Col>
            {this.displayCalendar()}
          </Col>
          <Col>
            <Form>
              <FormGroup>
                <Label for="package">Package</Label>
                <Input
                  type="select"
                  name="package"
                  id="package"
                  value={this.state.package}
                  onChange={this.handleChange}>
                  <option value="">Please select a package</option>
                  <option value="Gold">Gold</option>
                  <option value="Silver">Silver</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Kids">Kids</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="fullName">Please reselect or change the date</Label>
                <Input type="text"
                  name="date" id="date" value={this.convertDate(this.state.newdate)} disabled placeholder="Please select a date from the calendar" />
              </FormGroup>
              <FormGroup>
                <Label for="fullName">Full Name</Label>
                <Input type="text" value={this.state.fullName}
                  onChange={(e) => {
                    this.handleChange(e)
                  }} name="fullName" id="fullName" placeholder="Full Name" />
              </FormGroup>
              <FormGroup>
                <Label for="location">Location</Label>
                <Input type="text"
                  value={this.state.location}
                  onChange={(e) => {
                    this.handleChange(e)
                  }} name="location" id="location" placeholder="Location" />
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="myemail@email.com"
                  value={this.state.email}
                  onChange={(e) => {
                    this.handleChange(e)
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="telephone">Phone</Label>
                <Input
                  type="tel"
                  name="telephone"
                  id="telephone"
                  value={this.state.telephone}
                  onChange={(e) => {
                    this.handleChange(e)
                  }}
                  placeholder="How do we call you?" />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Customers Notes</Label>
                <Input type="textarea" style={{ height: 100 }} value={this.state.comments} onChange={e => this.setState({ comments: e.target.value })} name="text" id="exampleText" placeholder="Question? Comments? Special Requests?" />
              </FormGroup>
              <Button onClick={(e) => { this.onSubFormSubmit(); }}>Submit</Button>
            </Form>
          </Col>
        </Row>
      )
    }
  }

  convertDate(date) {
    var dateTest = new Date(date);
    return (
      dateTest.toLocaleString('en-GB').substring(0, dateTest.toLocaleString('en-GB').indexOf(','))
    )
  }

  displayCxDetails() {

    this.setState({ displayCxDetails: true });
    this.getBlockedOutDates();
  }

  displayForm(e) {
    if (e) {
      return (
        <Row>
          <Col>
            <Form>
              <Button onClick={this.displayCxDetails}>Alter Details</Button>
              {this.displayCustomerDetailsToAlter()}
              <Row form>

                <Col md={6}>
                  <FormGroup>
                    <Label for="cxAddress">Customer Address</Label>
                    <Input style={{ height: 200 }} defaultValue={this.state.cxAddress} name="cxAddress" type="textarea" onChange={this.handleChange} />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="venueAddress">Venue Address</Label>
                    <Input style={{ height: 200 }} defaultValue={this.state.venueAddress} name="venueAddress" type="textarea" onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label for="altTel">Alternative Contact Number</Label>
                  <Input type="text" name="altTel" defaultValue={this.state.altTel} onChange={this.handleChange} />
                </Col>

                <Col md={6}>
                  <Label for="venueTel">Venue Contact Number</Label>
                  <Input type="text" name="venueTel" defaultValue={this.state.venueTel} onChange={this.handleChange} />
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label for="discount">Discount Amount</Label>
                  <Input
                    type="number"
                    name="discount"
                    step="0.01"
                    onChange={this.handleChange}
                    defaultValue={this.state.discount} />
                </Col>
                <Col md={6}>
                  <Label for="finalAmount">Final Amount</Label>
                  <Input
                    type="number"
                    name="finalAmount"
                    step="0.01"
                    onChange={this.handleChange}
                    defaultValue={this.state.finalAmount}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <Label for="paymentType">Payment Method</Label>
                  <Input
                    type="select"
                    name="paymentType"
                    id="paymentType"
                    defaultValue={this.state.paymentType}
                    onChange={this.handleChange}>
                    <option value="">Please select a payment method</option>
                    <option value="paypal">Paypal</option>
                    <option value="bacs">BACS</option>
                  </Input>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="resultOfCC">Result Of Customer Call</Label>
                    <Input
                      type="select"
                      name="resultOfCC"
                      id="resultOfCC"
                      defaultValue={this.state.resultOfCC}
                      onChange={this.handleChange}>
                      <option value="">Please Select Result Of Customer Call</option>
                      <option value="Booked">Booked Awaiting Deposit</option>
                      <option value="Booked">Booked Deposit Paid</option>
                      <option value="Booked">Booked Paid</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="AFI">Awaiting Further Information</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="customNotes">Additional Notes and Details</Label>
                    <Input type="textarea" defaultValue={this.state.customNotes} name="customNotes" style={{ height: 200 }} onChange={this.handleChange} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {this.showAlert()}
                </Col>
              </Row>
              <Row form>

                <Col md={3}>
                  <FormGroup>
                    <Button onClick={(event) => { this.onFormSubmit(); this.props.action(); }}>Update Order Details</Button>

                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Button onClick={(event) => { if (window.confirm('Have you updated the status and populated all details?')) this.sendDepositEmail(this.state.data._original.Key); }}>Send Deposit Email</Button>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Button onClick={(event) => { if (window.confirm('Have you updated the status and populated all details?')) this.sendDepositConfirmedEmail(this.state.data._original.Key); }}>Send Deposit Recieved Email</Button>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Button onClick={(event) => { if (window.confirm('Have you updated the status and populated all details?')) this.sendFinalPaymentEmail(this.state.data._original.Key); }}>Send Final Payment Email</Button>
                  </FormGroup>
                </Col>

              </Row>
              <Row form>
                <Col md={3}>
                  <FormGroup>
                    <Button color="danger" onClick={(event) => { if (window.confirm('Have you updated the status and populated all details?')) this.sendConfirmOrder(this.state.data._original.Key); }}>Complete Order and Send Confirmation</Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )
    }
    else {
      return (
        <Container style={{ height: '70vh' }}>
          <Row>
            <Col>
              <Spinner style={{ width: '6rem', height: '6rem' }} type="grow" color="primary" />
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>Please wait checking for existing data...</h1>
            </Col>
          </Row>
        </Container>
      )
    }
  }

  showAlert() {
    if (this.state.emailSent) {
      return (
        <Alert color="success">
          Email Has Been Sent
        </Alert>
      )
    }
  }
  sendDepositEmail(k) {
    var data = {
      key: k,
      fullName: this.state.fullName,
      venueAddress: this.state.venueAddress,
      date: this.state.date,
      email: this.state.email,
      bookingId: this.state.data.bookingId
    }
    postData(JSON.stringify(data), '/finesse/sendDepositEmail.php');
    this.setState({ emailSent: true });
  }

  sendDepositConfirmedEmail(k) {
    var data = {
      key: k,
      fullName: this.state.fullName,
      venueAddress: this.state.venueAddress,
      date: this.state.date,
      email: this.state.email,
      bookingId: this.state.data.bookingId
    }
    postData(JSON.stringify(data), '/finesse/sendDepositConfirmedEmail.php')
    this.setState({ emailSent: true });
  }

  sendFinalPaymentEmail(k) {
    var data = {
      key: k,
      fullName: this.state.fullName,
      venueAddress: this.state.venueAddress,
      amount: this.state.finalAmount,
      date: this.state.date,
      email: this.state.email,
      bookingId: this.state.data.bookingId
    }
    postData(JSON.stringify(data), '/finesse/sendFinalPaymentEmail.php')
    this.setState({ emailSent: true });
  }

  sendConfirmOrder(k) {
    var data = {
      key: k,
      fullName: this.state.fullName,
      venueAddress: this.state.venueAddress,
      amount: this.state.finalAmount,
      date: this.state.date,
      email: this.state.email,
    }
    postData(JSON.stringify(data), '/finesse/sendConfirmOrderEmail.php')
    this.setState({ emailSent: true });
  }

  onFormSubmit() {
    var dateString = this.props.data.date;
    var dateParts = dateString.split("/");
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    var timeStamp = new Date(dateObject).getTime();
    var data = {
      EnhancedKey: this.state.EnhancedKey,
      package: this.state.package,
      bookingId: this.state.Key,
      altTel: this.state.altTel,
      customNotes: this.state.customNotes,
      cxAddress: this.state.cxAddress,
      discount: this.state.discount,
      finalAmount: this.state.finalAmount,
      resultOfCC: this.state.resultOfCC,
      venueAddress: this.state.venueAddress,
      venueTel: this.state.venueTel,
      Date: timeStamp,
      fullName: this.state.fullName,
      location: this.state.location,
      Email: this.state.email,
      Telephone: this.state.telephone,
      comments: this.state.comments,
      Status: true,
      booked: this.state.resultOfCC,
    }
    postData(JSON.stringify(data), '/finesse/AddEnhancedBookingData.php').then(response => { this.props.action(); });
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
            Customer Comments: {this.props.data.comments}<br />
            Booking Date: {this.state.date}<br />
          </Col>
        </Row>
        {this.displayForm(this.state.bookingDataLoaded)}
      </Container>
    )
  }
}