import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App.js';
import ScrollToTop from './components/ScrollToTop'


ReactDOM.render(
    <Router onUpdate={() => window.scrollTo(0, 0)}>
    <ScrollToTop>
      <App />
      </ScrollToTop>
    </Router>,
    document.getElementById("root")
  );
  



