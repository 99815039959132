import React, { Component } from 'react';
import '../App.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Link } from "react-scroll";
import {
  Row,
  Col,
  Button,
  Jumbotron,
} from 'reactstrap';
import Fade from 'react-reveal/Fade';
export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }




  render() {
    return (
      <Jumbotron>
        <div className="magic-mirror">
          <Fade bottom>
            <Row>
              <Col xl="6">
                <Row className="marginless hire-title">
                  <Col xl="12">
                    <h1 >Hire a Magic Mirror</h1>
                  </Col>
                </Row>
                <Row className="marginless">
                  <Col xl="12">
                    <Fade right>
                      <div className="photobooth-image">

                      </div>
                    </Fade>
                  </Col>
                </Row>
              </Col>
              <Col xl="6" className ="promoTextParent">
                <div className="promoText" >
                  <div className="promoList">
                    <div>UNLIMITED PRINTS</div>
                    <div>BOX OF STYLISH PROPS</div>
                    <div>MIRROR ATTENDANT</div>
                    <div>CUSTOMISED BACKGROUNDS</div>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Button size="lg" className="jumboButton">
                      <Link
                        to="book"
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={500}
                        onClick={this.toggle}

                      >
                        <strong>BOOK NOW</strong>
                      </Link>
                    </Button>
                  </div>
                </div>

              </Col>
            </Row>
          </Fade>
        </div>
      </Jumbotron>
    )
  }
}