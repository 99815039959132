import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Row, Col } from 'reactstrap';
import firebase, { db } from '../components/firebase.js';
import FileUploader from 'react-firebase-file-uploader';
import {postData} from '../scripts/PostData';

export default class GalleryUploader extends Component {
    constructor(props) {
        super(props);
        this.toggle=this.toggle.bind(this);
        this.changeValue=this.changeValue.bind(this);
        this.addImageToDatabase = this.addImageToDatabase.bind(this);
        this.state = {
            testimonialName: "",
            testimonialText: "",
            testimonials: [],
            albums:[],
            dropDownValue:"Please Select An Album",
            imageUploads:[],
            apiInfo:[],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({apiInfo:nextProps.apiInfo});  
      }
    componentDidMount(){
        this.getAlbums();
    }

    componentWillMount(){
        this.setState({ apiInfo: this.props.apiInfo });
      }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = filename => {
        this.setState({ avatar: filename, progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => this.setState({ avatarURL: url }, this.addImageToDatabase))
    };
    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }




    async getAlbums() {
        db
            .collection('albums')
            .get()
            .then(collection => {
                var albums = [];
                albums = collection.docs.map(doc => doc.data())
                const ids = collection.docs.map(doc => doc.id)
                this.setState({ ids }, function () { console.log() })
                var final = [];
                var finalArra = [];
                var i;
                for (i = 0; i < albums.length; i++) {
                    var Dataarray = [];
                    Dataarray = albums[i];
                    var IdArray = [];
                    IdArray = ({ "key": ids[i] });
                    final = { ...IdArray, ...Dataarray };
                    finalArra.push(final);
                }
                this.setState({ albums: finalArra }, function () { console.log() })
            })
    }



    addImageToDatabase() {
        var imageUploadsArray = this.state.imageUploads;
        imageUploadsArray.push(this.state.avatarURL);
        this.setState({imageUploads:imageUploadsArray});

        var data = {
            imageName: this.state.avatarURL,
            albumName: this.state.dropDownValue,
            albumKey: this.state.albumKey,
        }
        console.log();
        postData(JSON.stringify(data),'finesse/AddImages.php');
    }

    onAlbumFormSubmit() {
        var data = {
            AlbumName: this.state.albumName,
            BlogText: this.state.blogText,
            Status: true
        }
        db.collection('albums').doc().set(data);
    }

    changeValue(e) {
        this.setState({ dropDownValue: e.currentTarget.textContent })
    }
    updateKey(k){
        this.setState({albumKey:k})
    }

    render() {
        return (

            <Row>
                <Col>
                    <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle caret>
                            {this.state.dropDownValue}
                        </DropdownToggle>
                        <DropdownMenu right>
                            {this.state.apiInfo.map((data, i) => {
                                return (
                                    <DropdownItem><div onClick={(e) => {this.changeValue(e); this.updateKey(data.Key);}} value={data.Key}>{data.AlbumName}</div></DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </Dropdown>
                    <Row>
                        <Col>
                    <Label> Album Selected: {this.state.dropDownValue} <br/></Label>
                    </Col>
                  </Row>
                    <Label style={{ backgroundColor: '#57a9c8', color: 'white', padding: 10, borderRadius: 4, pointer: 'cursor' }}>
                        {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                        {this.displayUrls}
                        {this.state.avatarURL && <img style={{ maxHeight: 300, maxWidth: 200 }} src={this.state.avatarURL} alt="Your upload has been displayed" />}
                        <FileUploader
                            multiple
                            className="uploader"
                            accept="image/*"
                            name="avatar"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                        />
                    </Label>
                </Col>
            </Row>

        )
    }
}