import firebase from 'firebase/app'
import 'firebase/app';
import 'firebase/firebase-auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';
var config = {
  apiKey: "AIzaSyAVkc2IEf8frdTZY09GOucSO7LSZMESpVg",
  authDomain: "finessephotos-33eb3.firebaseapp.com",
  databaseURL: "https://finessephotos-33eb3.firebaseio.com",
  projectId: "finessephotos-33eb3",
  storageBucket: "finessephotos-33eb3.appspot.com",
  messagingSenderId: "228620276044"
  };
  firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const db = new firebase.firestore();
//export const auth = firebase.auth();
export default firebase;