import React, { Component } from 'react';
import {Alert, Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import firebase from './firebase.js';
import {postData} from '../scripts/PostData';
import FileUploader from 'react-firebase-file-uploader';

export default class AlbumUploader extends Component {
    constructor(props) {
        super(props);
        this.onAlbumFormSubmit = this.onAlbumFormSubmit.bind(this);
        this.state = {
            avatarURL:"",
            albumName:"",
            blogText:"",
            displayAlert:false,
        }
    }

    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
      this.setState({ isUploading: false });
      console.error(error);
    };
  
    handleUploadSuccess = filename => {
      this.setState({ avatar: filename, progress: 100, isUploading: false });
      firebase
        .storage()
        .ref("images")
        .child(filename)
        .getDownloadURL()
        .then(url => this.setState({ avatarURL: url }))
    };

    onAlbumFormSubmit() {
        var data = {
            AlbumName: this.state.albumName,
            AlbumImage: this.state.avatarURL,
            BlogText: this.state.blogText,
            Status: true
        }
        postData(JSON.stringify(data),'finesse/AddAlbum.php').then((response) => {this.props.action()});
        this.clearForm()
      }

      clearForm(){
          this.setState({albumName:"", blogText:"",avatarURL:"" }, this.displaySuccessMessage)
      }

      displaySuccessMessage(){
            this.setState({displayAlert:true})
      }

      displaySuccessAlert(){
          return(
              <div>
                  <Alert color="success">
                      Album was successfully created!
                  </Alert>
              </div>
          )
      }
    
    render() {


        return (
            <Row>
                <Col>
                {this.state.displayAlert ? (
                this.displaySuccessAlert()
                  ) : null}
                    <Form onSubmit={this.onAlbumFormSubmit}>
                        <FormGroup row>
                            <Label for="albumName" sm={2}>Name Of Album</Label>
                            <Col sm={10}>
                                <Input type="text" name="albumName" id="albumName" placeholder="Enter Text..." value={this.state.albumName} onChange={e => this.setState({ albumName: e.target.value })} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="blogText" sm={2}>Album Blog Text</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="blogText" id="blogText" placeholder="Enter Text..." value={this.state.blogText} onChange={e => this.setState({ blogText: e.target.value })} />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label sm={2}>Upload a cover image for album </Label>
                            <Col>
                            <Label  sm={10} style={{ backgroundColor: '#57a9c8', color: 'white', padding: 10, borderRadius: 4, pointer: 'cursor', float:"left" }}>
                                {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                                {this.state.avatarURL && <img style={{ maxHeight: 300, maxWidth: 200 }} src={this.state.avatarURL} alt="Your upload has been displayed" />}
                                <FileUploader
                                    className="uploader"
                                    accept="image/*"
                                    name="avatar"
                                    randomizeFilename
                                    mulitple
                                    storageRef={firebase.storage().ref("images")}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}
                                />
                            </Label>
                            </Col>
                            </FormGroup>
                        <Button type="button" style={{ marginLeft: 10 }} onClick={this.onAlbumFormSubmit}>Submit</Button>
                    </Form>
                </Col>
            </Row>


        )
    }
}